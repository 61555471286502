<template>
  <a :href="data&&data.ads[0].url" target="_blank">
    <img class="back-common" style="height: 735px;margin-top: 20px" :src="data&&data.ads[0].pic">

    </img>
  </a>
</template>

<script>


  export default {
    props:{
      data:Object
    },
    name: 'enterpriseSchoolCase',
    data() {
      return {
        isAnli: 0,
      }
    },
    methods: {}
  }
</script>

<style lang="scss" scoped>
  .qxt_content {
    width: 600px;
    height: 100%;
    float: left;
  }

  .qxt_content .buzhou span {
    font-size: 16px;
    font-family: "Microsoft YaHei", serif;
    color: rgb(255, 255, 255);
    height: 45px;
    line-height: 45px;
    display: inline-block;
    margin-right: 20px;
  }

  .tixing2 {
    color: white;
    padding-left: 20px;
    width: 140px;
    height: 40px;
    background-color: #fc7c40;
    line-height: 40px;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    font-size: 20px;
  }

  .tixing2:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 40px solid rgb(36, 43, 54);
    border-left: 25px solid transparent;
    position: absolute;
    right: 0;
  }

  .qxt_content {
    width: 600px;
    height: 100%;
    float: left;
  }

  .qxt_image {
    width: 600px;
    height: 100%;
    float: left;
    position: relative;
  }

  .example {
    width: 600px;

  }

  .isAnli {
    border-bottom: 1px solid #fc7c40;
  }

  .anli {
    height: 75px;
    line-height: 75px;
  }


  .anli span {
    font-size: 16px;
  }

  .anli span:hover {
    cursor: pointer;
  }

  .anli div {
    width: 300px;
    height: 75px;
    float: left;
    text-align: center;
  }

  .qxt_image_top {
    position: absolute;
    top: 0;
    width: 100%;
    height: 75px;
    background-color: #242b36;
    opacity: 0.6;
  }
</style>
